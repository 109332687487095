.nav {
    background-color: black;
    height: 70px;
    width: 100%;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
}

.navbarContainer {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    /* z-index: 1; */
    width: 100%;
    padding: 0 24px;
    /* max-width: 1100px; */
    color: white;
    /* overflow to hide navbarlink goind under the navbar menu*/
    overflow: hidden;
}

.mobileIcon {
    display: none;

}

/* .icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: rgb(255, 255, 255);
    transition: .3s;
  } */

.navMenu {
    display: flex;
    justify-content: space-between;
    min-width: 400px;
    box-sizing: border-box;
    /* when you adjust the below properties, adjust also the bottom padding of the navbarlink with same amount */
    padding-top: 20px;
    /* margin-right: 30px; */
}
.navbarLink {
    display: flex;
    background: rgb(0, 0, 0);
    /* width:160px; */
    height: 80px;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    border-radius: 20px 20px 0 0;
    transition: 0,6s ease-in-out;
}

.navbarLink:hover {
    /* background-color: #01bf71; */
    background-color: #08d1d8;
    color: black;
    transition: 0,6s ease-in-out;
}

/* .navbarLink:active {
    background-color: #08d1d8;
} */

.logoutButton {
    display: flex;
    justify-content: center;
    height: 40;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 0.8rem;
    outline: none;
    border: none;
    cursor: pointer;
    margin-right: 15px;
    /* background-color:#01bf71; */
    background-color:#08d1d8;
    text-decoration: none;
    color: black;
    transition: all 0.2s ease-in-out;
}

.logoutButton:hover {
    transform: all 0.2s ease-in-out;
    background-color: #fff;
    color: #010606;
}

/* li:hover{
    color: aquamarine;
  }; */

@media screen and (max-width: 760px) {
    .navMenu {
        display: none;
    }

    .mobileIcon {
        /* visibility: visible; */
        display: block;
        /* position: absolute; */
        /* top: 0;
        right: 0;
        transform: translate(-100%, 60%); */
        font-size: 1.9rem;
        cursor: pointer;
        align-items: center;
        /* margin-right: 22px */
        color: rgb(255, 255, 255);
        margin-top: 5px;
        z-index: 20;
    }
    .logoutButton {
        display: none;
    }
}