
.sidebarContainer {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
}

.closeIcon {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    font-size: 2rem;
    cursor: pointer;
    outline: none;

}

.SidebarLinksContainer {
    color: white;
    display: grid;
    grid-template-columns: 1 fr;
    grid-template-rows: repeat(4, 80px);
    text-align: center;
}
.SidebarLink {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.SidebarLink:hover {
    color: #08d1d8;
    transition: 0.2s ease-in-out;

}

.SideButtonWrap {
    display: flex;
    justify-content: center;

}
.SideButton {
    border-radius: 50px;
    background:#08d1d8;
    white-space: nowrap;
    padding: 16px 64px;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;

}
.SideButton:hover {
    transition: 0.2s ease-in-out;
    background: #fff;
    color: #010606;
}

@media screen and (max-width: 480px) {

    .SidebarLinksContainer {
        grid-template-rows: repeat(4, 60px);
        
    }

}