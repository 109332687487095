@import url(https://fonts.googleapis.com/css?family=Encode+Sans+Condensed:400,600);
@import url(https://fonts.googleapis.com/css?family=Encode+Sans+Condensed:400,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page {
  width: 100%;
  height: 100vh;
  background: #fdfdfd;
  font-family: 'Encode Sans Condensed', sans-serif;
  font-weight: 600;
  letter-spacing: .03em;
  color: #212121;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  outline: none;
}

strong {
  font-weight: 600;
}

.page {
  width: 100%;
  height: 100vh;
  background: #fdfdfd;
  font-family: 'Encode Sans Condensed', sans-serif;
  font-weight: 600;
  letter-spacing: .03em;
  color: #212121;
}

header {
  display: flex;
  position: fixed;
  width: 100%;
  height: 70px;
  background: #212121;
  color: #fff;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

main {
  padding: 70px 20px 0;
  display: flex;
  flex-direction: column;
  height: 80%;
}

main > div {
  margin: auto;
  max-width: 600px;
}

main h2 span {
  color: #BF7497;
}

main p {
  line-height: 1.5;
  font-weight: 200;
  margin: 20px 0;
}

main small {
  font-weight: 300;
  color: #888;
}

#nav-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  pointer-events: none;
}
#nav-container .bg {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  background: #000;
}
#nav-container:focus-within .bg {
  visibility: visible;
  opacity: .6;
}
#nav-container * {
  visibility: visible;
}

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  border-radius: 0;
  height: 70px;
  width: 30px;
  cursor: pointer;
  pointer-events: auto;
  margin-left: 25px;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.icon-bar {
  display: block;
  width: 100%;
  height: 3px;
  background: #aaa;
  transition: .3s;
}
.icon-bar + .icon-bar {
  margin-top: 5px;
}

#nav-container:focus-within .button {
  pointer-events: none;
}
#nav-container:focus-within .icon-bar:nth-of-type(1) {
  -webkit-transform: translate3d(0,8px,0) rotate(45deg);
          transform: translate3d(0,8px,0) rotate(45deg);
}
#nav-container:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}
#nav-container:focus-within .icon-bar:nth-of-type(3) {
  -webkit-transform: translate3d(0,-8px,0) rotate(-45deg);
          transform: translate3d(0,-8px,0) rotate(-45deg);
}

#nav-content {
  margin-top: 70px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 70px);
  background: #ececec;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  will-change: transform;
  contain: paint;
}

#nav-content ul {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#nav-content li a {
  padding: 10px 5px;
  display: block;
  text-transform: uppercase;
  transition: color .1s;
}

#nav-content li a:hover {
  color: #BF7497;
}

#nav-content li:not(.small) + .small {
  margin-top: auto;
}

.small {
  display: flex;
  align-self: center;
}

.small a {
  font-size: 12px;
  font-weight: 400;
  color: #888;
}
.small a + a {
  margin-left: 15px;
}

#nav-container:focus-within #nav-content {
  -webkit-transform: none;
          transform: none;
}






* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

a,
a:visited,
a:focus,
a:active,
a:link {
  text-decoration: none;
  outline: 0;
}

a {
  color: currentColor;
  transition: .2s ease-in-out;
}

h1, h2, h3, h4 {
  margin: 0;
}

ul {
  padding: 0;
  list-style: none;
}

.import {
  vertical-align: middle;
  align-self: center;
  height: auto;
  width: 20%;
  margin: 1%;
}

img {
  vertical-align: middle;
  height: auto;
  width: 100%;
}
* {
  outline: none;
}

strong {
  font-weight: 600;
}

.page {
  width: 100%;
  height: 100vh;
  background: #fdfdfd;
  font-family: 'Encode Sans Condensed', sans-serif;
  font-weight: 600;
  letter-spacing: .03em;
  color: #212121;
}

header {
  z-index: 1;
  display: flex;
  position: fixed;
  width: 100%;
  height: 70px;
  background: #212121;
  color: #fff;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

main {
  padding: 70px 20px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

main > div {
  margin: auto;
  max-width: 600px;
}

main h2 span {
  color: #BF7497;
}

main p {
  line-height: 1.5;
  font-weight: 200;
  margin: 20px 0;
}

main small {
  font-weight: 300;
  color: #888;
}

#nav-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  z-index: 1;
}
#nav-container .bg {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  background: #000;
}
#nav-container:focus-within .bg {
  visibility: visible;
  opacity: .6;
}
#nav-container * {
  visibility: visible;
}

/* .button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  border-radius: 0;
  height: 70px;
  width: 30px;
  cursor: pointer;
  pointer-events: auto;
  margin-left: 25px;
  touch-action: manipulation;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
} */
/* .icon-bar {
  display: block;
  width: 100%;
  height: 3px;
  background: #aaa;
  transition: .3s;
}
.icon-bar + .icon-bar {
  margin-top: 5px;
} */

#nav-container:focus-within .button {
  pointer-events: none;
}
#nav-container:focus-within .icon-bar:nth-of-type(1) {
  -webkit-transform: translate3d(0,8px,0) rotate(45deg);
          transform: translate3d(0,8px,0) rotate(45deg);
}
#nav-container:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}
#nav-container:focus-within .icon-bar:nth-of-type(3) {
  -webkit-transform: translate3d(0,-8px,0) rotate(-45deg);
          transform: translate3d(0,-8px,0) rotate(-45deg);
}

#nav-content {
  margin-top: 70px;
  padding: 20px;
  width: 90%;
  max-width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 70px);
  background: #ececec;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  will-change: transform;
  contain: paint;
}

#nav-content ul {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#nav-content li a {
  padding: 10px 5px;
  display: block;
  text-transform: uppercase;
  transition: color .1s;
}

#nav-content li a:hover {
  color: #BF7497;
}

#nav-content li:not(.small) + .small {
  margin-top: auto;
}

.small {
  display: flex;
  align-self: center;
}

.small a {
  font-size: 12px;
  font-weight: 400;
  color: #888;
}
.small a + a {
  margin-left: 15px;
}

#nav-container:focus-within #nav-content {
  -webkit-transform: none;
          transform: none;
}

.chartsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: rgb(227, 226, 226); */
  background-color: whitesmoke;
}

.chart {
  display: flex;
  background-color: rgb(255, 255, 255);
  /* width: 900px; */
  justify-content: center;
  align-items: center;
  margin: 30px;
  padding-top: 10px;
  border-radius: 30px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;

}
.chart:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.importUploadButtonsContainer {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex: 10% 1;
  /* margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px; */
}

.importUploadIcon {
  pointer-events: none;
}

.importUploadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.importUploadButton:hover {
  /* color: goldenrod; */
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  transition: all 0.2s ease-out;
}







* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

a,
a:visited,
a:focus,
a:active,
a:link {
  text-decoration: none;
  outline: 0;
}

a {
  color: currentColor;
  transition: .2s ease-in-out;
}

h1, h2, h3, h4 {
  margin: 0;
}

/* ul {
  padding: 0;
  list-style: none;
} */

img {
  vertical-align: middle;
  height: auto;
  width: 100%;
}

/* ====Upload and Import Buttons========*/



.filterMenuContainer {
    position: -webkit-sticky;
    position: sticky;
    top:70px;
    display: flex;
    justify-content: space-around;
    align-items: right;
    background-color: #08d1d8;
    width: 100%;
    min-height: 30px;
    z-index: 1;
}

.menuItemWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 90% 1;
    grid-gap: 40px;
    gap: 40px;
    /* padding-left: 250px;
    padding-right: 250px; */

}

.menuButton {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color:transparent;
    border: 1px solid rgb(0, 0, 0);
    font-size: medium;
    cursor: pointer;
    /* padding-left: 40px;
    padding-right: 40px; */
}

.menuButtonActive {
    background: black;
    color: whitesmoke;
    border: none;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid black;
    font-size: medium;
    cursor: pointer;
    /* padding-left: 40px;
    padding-right: 40px; */
}

.menuButton:hover {
    background: black;
    color: whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.3s ease-out;
}

@media screen and (max-width: 760px) {
    .filterMenuContainer {
        flex-direction: column;
        padding-bottom: 10px;
        position: relative;
        top: 0px;
    }
    .menuItemWrapper {
        margin-top: 10px;
        flex-direction: column;
        padding-left: 100px;
        padding-right: 100px;
        grid-gap: 0px;
        gap: 0px;
        
    }
    .menuButton {
        background: transparent;
        color: black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        border-left: 1px solid #08d1d8;
        border-right: 1px solid #08d1d8 ;
        outline: none;
        font-size: large;
        margin-top: 0px;
        /* padding-top: 5px;
        padding-bottom: 5px; */
        border-radius: 0px;
        width: 100%;
        
    }

    .menuButtonActive {
        background: linear-gradient(90deg, rgba(8,209,216,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(8,209,216,0) 100%);
        color: whitesmoke;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        border-left: 1px solid #08d1d8;
        border-right: 1px solid #08d1d8 ;
        font-size: large;
        margin-top: 0px;
        /* padding-top: 5px;
        padding-bottom: 5px; */
        border-radius: 0px;
        width: 100%;
        
    }
    .menuButton:hover {
        background: linear-gradient(90deg, rgba(8,209,216,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(8,209,216,0) 100%);
        /* padding-left: 40px;
        padding-right: 40px; */
        transition: 0,3s all ease-in-out;
    }

}


.nav {
    background-color: black;
    height: 70px;
    width: 100%;
    /* margin-top: -80px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
}

.navbarContainer {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    /* z-index: 1; */
    width: 100%;
    padding: 0 24px;
    /* max-width: 1100px; */
    color: white;
    /* overflow to hide navbarlink goind under the navbar menu*/
    overflow: hidden;
}

.mobileIcon {
    display: none;

}

/* .icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: rgb(255, 255, 255);
    transition: .3s;
  } */

.navMenu {
    display: flex;
    justify-content: space-between;
    min-width: 400px;
    box-sizing: border-box;
    /* when you adjust the below properties, adjust also the bottom padding of the navbarlink with same amount */
    padding-top: 20px;
    /* margin-right: 30px; */
}
.navbarLink {
    display: flex;
    background: rgb(0, 0, 0);
    /* width:160px; */
    height: 80px;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    border-radius: 20px 20px 0 0;
    transition: 0,6s ease-in-out;
}

.navbarLink:hover {
    /* background-color: #01bf71; */
    background-color: #08d1d8;
    color: black;
    transition: 0,6s ease-in-out;
}

/* .navbarLink:active {
    background-color: #08d1d8;
} */

.logoutButton {
    display: flex;
    justify-content: center;
    height: 40;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 0.8rem;
    outline: none;
    border: none;
    cursor: pointer;
    margin-right: 15px;
    /* background-color:#01bf71; */
    background-color:#08d1d8;
    text-decoration: none;
    color: black;
    transition: all 0.2s ease-in-out;
}

.logoutButton:hover {
    -webkit-transform: all 0.2s ease-in-out;
            transform: all 0.2s ease-in-out;
    background-color: #fff;
    color: #010606;
}

/* li:hover{
    color: aquamarine;
  }; */

@media screen and (max-width: 760px) {
    .navMenu {
        display: none;
    }

    .mobileIcon {
        /* visibility: visible; */
        display: block;
        /* position: absolute; */
        /* top: 0;
        right: 0;
        transform: translate(-100%, 60%); */
        font-size: 1.9rem;
        cursor: pointer;
        align-items: center;
        /* margin-right: 22px */
        color: rgb(255, 255, 255);
        margin-top: 5px;
        z-index: 20;
    }
    .logoutButton {
        display: none;
    }
}

.sidebarContainer {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
}

.closeIcon {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    font-size: 2rem;
    cursor: pointer;
    outline: none;

}

.SidebarLinksContainer {
    color: white;
    display: grid;
    grid-template-columns: 1 fr;
    grid-template-rows: repeat(4, 80px);
    text-align: center;
}
.SidebarLink {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.SidebarLink:hover {
    color: #08d1d8;
    transition: 0.2s ease-in-out;

}

.SideButtonWrap {
    display: flex;
    justify-content: center;

}
.SideButton {
    border-radius: 50px;
    background:#08d1d8;
    white-space: nowrap;
    padding: 16px 64px;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    text-decoration: none;

}
.SideButton:hover {
    transition: 0.2s ease-in-out;
    background: #fff;
    color: #010606;
}

@media screen and (max-width: 480px) {

    .SidebarLinksContainer {
        grid-template-rows: repeat(4, 60px);
        
    }

}
