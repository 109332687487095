

.filterMenuContainer {
    position: sticky;
    top:70px;
    display: flex;
    justify-content: space-around;
    align-items: right;
    background-color: #08d1d8;
    width: 100%;
    min-height: 30px;
    z-index: 1;
}

.menuItemWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 90%;
    gap: 40px;
    /* padding-left: 250px;
    padding-right: 250px; */

}

.menuButton {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color:transparent;
    border: 1px solid rgb(0, 0, 0);
    font-size: medium;
    cursor: pointer;
    /* padding-left: 40px;
    padding-right: 40px; */
}

.menuButtonActive {
    background: black;
    color: whitesmoke;
    border: none;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid black;
    font-size: medium;
    cursor: pointer;
    /* padding-left: 40px;
    padding-right: 40px; */
}

.menuButton:hover {
    background: black;
    color: whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.3s ease-out;
}

@media screen and (max-width: 760px) {
    .filterMenuContainer {
        flex-direction: column;
        padding-bottom: 10px;
        position: relative;
        top: 0px;
    }
    .menuItemWrapper {
        margin-top: 10px;
        flex-direction: column;
        padding-left: 100px;
        padding-right: 100px;
        gap: 0px;
        
    }
    .menuButton {
        background: transparent;
        color: black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        border-left: 1px solid #08d1d8;
        border-right: 1px solid #08d1d8 ;
        outline: none;
        font-size: large;
        margin-top: 0px;
        /* padding-top: 5px;
        padding-bottom: 5px; */
        border-radius: 0px;
        width: 100%;
        
    }

    .menuButtonActive {
        background: linear-gradient(90deg, rgba(8,209,216,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(8,209,216,0) 100%);
        color: whitesmoke;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        border-left: 1px solid #08d1d8;
        border-right: 1px solid #08d1d8 ;
        font-size: large;
        margin-top: 0px;
        /* padding-top: 5px;
        padding-bottom: 5px; */
        border-radius: 0px;
        width: 100%;
        
    }
    .menuButton:hover {
        background: linear-gradient(90deg, rgba(8,209,216,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 70%, rgba(8,209,216,0) 100%);
        /* padding-left: 40px;
        padding-right: 40px; */
        transition: 0,3s all ease-in-out;
    }

}